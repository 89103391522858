.createDiscount .form-control:focus {
    border-color: #8167cc !important;
    box-shadow: 0 0 0 0.2rem #8167cc !important;
}

.modal {
    max-width: 600px;
    max-height: 98%;
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    border-radius: 8px;
    -webkit-box-shadow: 0px 10px 50px rgb(0 0 0 / 10%);
    box-shadow: 0px 10px 50px rgb(0 0 0 / 10%);
    overflow: auto;
}

.createDiscount label {
    font-size: 14px;
    font-weight: 500;
}

.textPrimary {
    color: #8167cc;
}

.customButton {
    border-radius: 5px;
    padding: 6px 18px;
    color: #fff;
}

.customButton:disabled {
    opacity: 0.5;
}

.customButton.primary {
    background: #8167cc 0% 0% no-repeat padding-box;
    border: 1px solid #8167cc;
}

.container {
    width: 100%;
    background-color: white;
    margin-top: 20px;
}

.titleText {
    font-family: DM Sans;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: var(--black);
}

.close_svg {
    font-size: 16px !important;
    cursor: pointer;
    fill: black !important;
    position: absolute;
    top: -5px;
    right: -7px;
    background-color: transparent;
}

.infoBanner {
    background-color: #ecebf8;
    font-size: 14px;
    color: #8167cc;
}

.selected_email {
    padding: 3px 7px;
    border-radius: 20px;
    font-size: 14px;
    white-space: nowrap;
    font-weight: 400;
    margin: 2px;
    display: inline-block;
    cursor: pointer;
    position: relative;
}

.selected_email:nth-child(4n+1) {
    background-color: #fff2d7;
}

.selected_email:nth-child(4n+2) {
    background-color: #e4f9f4;
}

.selected_email:nth-child(4n+3) {
    background-color: #feeee7;
}

.selected_email:nth-child(4n+4) {
    background-color: #ebf4f3;
}

.autoComplete {
    position: relative;
}

.loyalityDropdown {
    position: absolute;
    padding: 14px;
    background-color: #fff;
    border-radius: 4px;
    border: solid 1px #ededed;
    right: 0;
    left: 0;
    -webkit-box-shadow: 0px 10px 8px rgb(0 0 0 / 10%);
    box-shadow: 0px 10px 8px rgb(0 0 0 / 10%);

}

.ghostInput {
    border: none;
    font-size: 14px;
    width: 100%;
}

.listHolder {
    max-height: 300px;
    overflow-y: auto;
}

.userRow {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: solid 1px #ededed;
}

.userRow:hover {
    background-color: #fbfbfb;
    cursor: pointer;
}

.userRow span {
    margin-right: 6px;
}

.displayPicturePlaceholder {
    width: 34px;
    height: 34px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
}

.userRow:nth-child(4n+1) .displayPicturePlaceholder {
    background-color: #fff2d7;
}

.userRow:nth-child(4n+2) .displayPicturePlaceholder {
    background-color: #e4f9f4;
}

.userRow:nth-child(4n+3) .displayPicturePlaceholder {
    background-color: #feeee7;
}

.userRow:nth-child(4n+4) .displayPicturePlaceholder {
    background-color: #ebf4f3;
}

.chip {
    margin: 5px;
}

.placeholder {
    color: #707070;
    font-size: 14px;
}

.selectDrop {
    position: relative !important;
    display: block !important;
}

.selectError {
    border: 1px solid red;
    border-bottom: 1px solid red !important;
    background-color: #F2F2F4;
    margin-top: 5px;
    border-radius: 5px;
    padding-left: 10px;
}

.tickMark {
    float: right;
}

.selectError:after {
    border-bottom: none !important;
}

.selectError:before {
    border-bottom: none !important;
}

.select {
    background-color: #efefef;
    border-bottom: none !important;
    margin-top: 5px;
    border-radius: 5px;
    color: black !important;
    max-height: 100px;
    overflow-y: scroll;
}

.select>div {
    padding: 10px;
    border-radius: 5px;
    /* width: 300px !important; */
}

.select::before {
    border-bottom: none !important;
}

.select:after {
    border-bottom: none !important;
}

.button_link {
    color: #6c5dd3;
    text-decoration: underline;
    border: none;
    margin: 0;
    background-color: transparent;
    padding: 0;
}

.couponBox {
    display: flex;
    flex-grow: inherit;
    flex-direction: row;
    justify-content: space-between;
    width: min(256px, 100%) !important;
}

.boxStyles {
    width: 15vw !important;
    margin: 2vw 2vw 1vw 0 !important;
    opacity: 0.5;
    font-size: 2vw;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
}


.buttonAddText {
    background-color: #6c5dd3;
    padding: 0.5vw 2vw;
    border-radius: 5vw;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
}

.outerBox {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.infoFlex {
    display: flex;
    flex-direction: row;
    margin: 20px 0 0px 0;
}

.infoImage {
    height: 20px;
    margin: 3px 5px 0 0;
}

.infoText {
    margin: 3vw 0 0 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
}

.datagrid {
    width: 100%;
}

.rightBox {
    width: 25%;
    margin: 1vw;
}

.card {
    padding: 2vw 1vw 2vw 1vw;
}


.coupon_reason {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
}

.rightHead {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #212121;
}

.rightBody {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #8e8e8e;

}

.discountHead {
    margin: 15px 0 15px 0;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.46;
    letter-spacing: normal;
    text-align: left;
    color: #212121;
    padding-left: 20px;
}

.deleteButton {
    border: none;
    color: #df635d;
}

.mobile_actions {
    display: none;
}

.preview_cta {
    color: #6c5dd3;
    margin: 10px 20px 7px 20px;
    float: right;
}

.save_cta {
    background-color: #6c5dd3;
    padding: 7px 20px 7px 20px;
    border-radius: 20px;
    color: white;
    font-size: 16px;
    justify-content: right;
}

.learn {
    display: block;
    float: right;
}

.listingCard {
    background-color: #fff;
    border-radius: 6px;
    padding: 6px 10px;
    margin: 5px;
    border: solid 1px #ededed;
    display: inline-block;
    cursor: pointer;
}

.listingCardActive {
    border-color: #6c5dd3;
    color: #6c5dd3;
}

.bottomDiv {
    display: none;
}

@media only screen and (max-width: 780px) {
    .couponBox {
        display: flex;
        margin: 2vw 2vw 2vw 0;
        flex-direction: column;
        justify-content: space-around;
    }

    .preview_cta {
        margin: 7px 30px 0 0;
    }

    .outerBox {
        margin: 10px 0px;
        padding-left: 0;
    }

    .mobile_actions {
        position: fixed;
        display: flex;
        flex-direction: row;
        bottom: 0;
        margin-top: 2rem;
        padding: 0.75rem 1.5rem;
        box-shadow: 0px 2px 4px #00000029;
        width: 100%;
        z-index: 1;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #EAEAEA;
        border-radius: 4px;
        opacity: 1;
        justify-content: center;
    }

    .boxStyles {
        width: 100% !important;
        margin: 4vw 1vw 3vw 0;
        opacity: 0.5;
        font-size: 2vw;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.3;
        letter-spacing: normal;
        text-align: left;
    }

    .infoImage {
        height: 3.5vw;
        margin: 7px 5px 0 0;
    }

    .rightBox {
        display: none;
    }

    .container {
        width: 100%;
        background-color: white;
    }

    .rightHead {
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #212121;
    }

    .rightBody {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #8e8e8e;

    }

    .deleteButtonMobile {
        border: none;
        outline: none !important;
        background: transparent;
        color: white;
        width: 100%;
    }

    .is_mobile {
        display: none;
    }

    .learn {
        display: none;
    }

    .bottomDiv {
        display: block !important;
        position: fixed;
        bottom: 0;
        left: 0;
        border-top: 1.5px solid #E2E2E2;
        padding: 1rem;
        width: 100vw;
        background-color: #ffffff;
        z-index: 99;
    }

    .primary_btn {
        background-color: #6C5DD3 !important;
        color: #ffffff !important;
        font-size: 14px !important;
        border-radius: 4px !important;
        text-transform: capitalize !important;
        width: 100% !important;
        text-align: center !important;
    }
}