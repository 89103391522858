.limitedOfferContainer {
  margin-left: 30px;
  margin-top: 30px;
}
.limitedOfferheader {
  display: flex;
  justify-content: space-between;
}
.buttonLimitedOffer {
  background-color: #6c5dd3 !important;
  color: white !important;
  padding: 5px 10px;
  outline: none;
  border: none;
  border-radius: 5px;
}
.modalContainerguide {
  padding: 25px !important;
}
.buttonLimited {
  margin-top: 10px;
}
.emptyState {
  padding-left: 80px;
  padding-top: 80px;
}
.offerImage {
  margin-bottom: 10px;
}
.learnmoreOffer {
  color: #6c5dd3;
  text-decoration: underline;
  text-align: end;
  outline: none;
  border: none;
  background-color: transparent;
  margin-right: 20px;
  cursor: pointer;
}
.rightbuttoncontainer {
  display: flex;
}
.offerTitle {
  font-weight: 500;
  font-size: 18px;
}
.learnmoreheader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.close {
  font-size: 30px;
  margin-top: -15px;
  cursor: pointer;
}
.scheduled {
  background-color: burlywood;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}
.expired {
  background-color: #ffb2b2;
  color: red;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}
.active {
  color: green;
  background-color: #bcf5bc;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}
.offerslistItem {
  position: absolute !important;
  z-index: 1000;
  background-color: white;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.16);
  padding: 20px 25px;
  border-radius: 5px;
  right: 4%;
  margin-top: -15px;
}
.close {
  display: block;
  float: right;
  font-size: 18px;
  margin-right: -10px;
}
.menuitem {
  padding: 5px 0;
  cursor: pointer;
}

.confirmbuttonContainer{
  display: flex;
  justify-content: center;
}

.confirmbuttonContainer button {
  color: white;
  background-color: #6c5dd3;
  height: 30px;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-top: 20px;
  margin-right: 15px;
  padding: 0 10px;
}
.clear_filter {
  margin: 30px 0;
  cursor: pointer;
}

.confirmationalert {
  padding: 30px !important;
}
@media screen and (max-width: 780px) {
  .mobileParent {
    margin-bottom: 39px;
  }
  .mobileParent form {
    padding-left: 15px !important;
  }
  .bookedSessionMobile {
    width: 42vw;
    min-width: 100px !important;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .bookedInputMobile > div {
    background-color: #ffff !important;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.1);
  }
  .bookedInputMobile > div::before {
    border-bottom: none !important;
  }
  .bookedInputMobile > div::after {
    border-bottom: none !important;
  }
  .bookedSessionMobile:after {
    border-bottom: unset !important;
  }
  .listItem {
    width: 100vw !important;
    padding: 0 !important;
  }
  .filterMobile {
    margin-top: 10px !important;
    margin-right: -5px !important;
    margin-bottom: 35px !important;
  }

  .bookedSessionMobile > div::before {
    border-bottom: none !important;
  }
  .bookedSessionMobile > div::after {
    border-bottom: none !important;
  }

  .bookedSessionMobile input {
    background-color: #ffff !important;
  }
  .bookedSessionMobile > div > div {
    background-color: #ffff !important;
  }
  .clearFilterMobile {
    padding-left: 15px;
  }

  .options_svg {
    margin-left: 75vw;
  }
  .mobileHeader {
    margin: 0 15px 10px 15px !important;
  }

  .table_header {
    font-size: 18px;
  }
  .listItem > div:nth-child(2) > div {
    border: none;
  }
  .limitedOfferContainer {
    margin-left: 0px;
    margin-top: 0px;
  }
  .stickyButton {
    position: fixed;
    text-align: center;
    bottom: 0;
    background-color: white;
    height: 50px;
    width: 100%;
    padding-top: 8px;
  }
  .limitedOfferheader {
    padding: 15px;
  }
  .modalContainerguide {
      padding: 15px !important;
      width: 90% !important;
  }
  .alert {
    width: 90% !important;
    bottom: auto !important;
    right: 5% !important;
    transform: none !important;
    left: 5% !important;
    top: 5% !important;
  }
  .emptyState {
    padding: 15px
  }
  .status {
    float: right;
    width: fit-content;
  }
  .clearFilterMobile {
    padding-left: 15px;
  }
  .menuitem,  .close{
    color: black !important;
  }
  .is_mobile {
    display: none;
  }
}
